import React, { memo } from "react"
import isEqual from "lodash/isEqual"

import Ratings from "../Ratings"
import DefaultSection from "../DefaultSection"

type Props = {|
  data: Object,
  starClassName?: string,
  wrapperClassName?: string,
  className?: string,
  itemClassName?: string,
|}

const Testimonials = ({
  data = [],
  starClassName = "",
  wrapperClassName = "",
  className = "",
  itemClassName = "",
}: Props) => {
  const [testimonials = {}] = data
  const { items = [], primary = { testimonials_title: {} } } = testimonials
  const formatedData = items.map(item => ({
    name: item.testimonial_owner_name,
    quote: item.testimonial_quote,
    review: item.testimonial_content,
    rating: item.testimonial_rating,
  }))

  return (
    <DefaultSection
      title={primary.testimonials_title.text}
      testId="testimonials-section"
      isPrimary
      classNames={`w-full mx-auto bg-gray-960 ${className ||
        "lg:py-96 py-100 mt-58"}`}
      sectionClassNames={{
        title:
          "uppercase text-6xl sm:text-3xl 2xl:text-67l lg:leading-30 sm:leading-28 leading-30 font-primary text-center px-20 sm:px-0 text-white",
        subtitle:
          "font-base-light text-base md:text-4.5xl leading-normal md:leading-36 mx-10",
      }}
      id="testimonials"
    >
      <Ratings
        ratings={formatedData}
        reviewClassNames={
          "leading-22 text-3xs font-avenir-roman text-left text-white opacity-80"
        }
        starClassName={starClassName}
        wrapperClassName={wrapperClassName}
        itemClassName={itemClassName}
      />
    </DefaultSection>
  )
}

export default memo(Testimonials, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
