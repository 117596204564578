// @flow
import React, { memo } from "react"

import Review from "./Review"

type RatingsProps = {|
  ratings?: Array<Object>,
  reviewClassNames: String,
  starClassName?: string,
  wrapperClassName?: string,
  itemClassName?: string,
|}

const Ratings = ({
  ratings = [],
  reviewClassNames,
  starClassName = "",
  wrapperClassName = "",
  itemClassName = "",
}: RatingsProps) => {
  return (
    <div
      className={`bg-gray-960 ${wrapperClassName}`}
      data-cy="ratings-section"
    >
      {ratings.length > 0 &&
        ratings.map((item, key) => (
          <Review
            review={item}
            key={"rating-" + key}
            reviewClassNames={reviewClassNames}
            starClassName={starClassName}
            className={itemClassName}
          />
        ))}
    </div>
  )
}

export default memo<RatingsProps>(Ratings)
