import React from "react"
import { Icon } from "@lesmills/gatsby-theme-common"

type SlideArrowProps = {|
  className: string,
  style: object,
  onClick: () => void,
  isCommunicateSection?: Boolean,
|}

export const NextArrow = ({
  className,
  style,
  onClick,
  isCommunicateSection = false,
}: SlideArrowProps) => (
  <div
    className={`${className} slp-list-slick-next z-99 block mb-20 ${isCommunicateSection &&
      `slick-communicate-list`}`}
    style={style}
    onClick={onClick}
  >
    <Icon
      icon="prev"
      type="base"
      classNames="w-65 h-65 transform rotate-180 opacity-80 hover:opacity-100"
      handleOnClick={onClick}
    />
  </div>
)

export const PrevArrow = ({
  className,
  style,
  onClick,
  isCommunicateSection = false,
}: SlideArrowProps) => (
  <div
    className={`${className} slp-list-slick-prev z-99 block ${isCommunicateSection &&
      `slick-communicate-list`}`}
    style={style}
    onClick={onClick}
  >
    <Icon
      icon="prev"
      type="base"
      classNames="w-65 h-65 opacity-80 hover:opacity-100"
      handleOnClick={onClick}
    />
  </div>
)
