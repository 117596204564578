import React, { useState, memo } from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"

import { AccordionItem } from "@lesmills/gatsby-theme-common"

import QuestionList from "./QuestionList"

type Props = {|
  data: Array<object>,
  title: object,
  opened: boolean,
  classNames?: Object,
|}

const FrequentlyAskedQuestions = ({
  data,
  title,
  opened,
  classNames,
}: Props) => {
  const [maxHeight, setMaxHeight] = useState(1000)

  const [faqs = {}] = data || []

  const handleToggleContent = value => {
    setMaxHeight(maxHeight + value)
  }

  return (
    <AccordionItem
      classNames={{
        title:
          "font-primary text-3xl sm:text-6xl leading-28 text-white uppercase mx-auto max-w-49/100 sm:max-w-full text-center",
        icon: " absolute lg:left-7/10 left-85/100",
        accordion: " bg-gray-960 sm:py-110 py-60 accordion-item-white relative",
      }}
      item={{
        title: <RichText render={title.raw} />,
        children: (
          <QuestionList
            questions={faqs.items}
            handleToggleContent={handleToggleContent}
            readLessBtn={data.read_less_button_label || []}
            classNames={classNames}
          />
        ),
      }}
      onToggleFromOutside={handleToggleContent}
      outSideHeight={maxHeight}
      icon="black-back"
      opened={opened}
    />
  )
}

export default memo(FrequentlyAskedQuestions, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
