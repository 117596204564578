// @flow
import React, { memo } from "react"

type Props = {|
  children: Node,
  classNames?: string,
  title: string,
  subtitle?: string,
  isPrimary?: boolean,
  titleClassNames?: string,
  subtitleClassNames?: string,
  testId?: string,
  sectionClassNames?: Object,
  id?: string,
|}

const DefaultSection = ({
  children,
  classNames = "",
  title,
  subtitle,
  isPrimary = false,
  titleClassNames = "",
  subtitleClassNames = "",
  sectionClassNames = {
    title: "",
    subtitle: "",
  },
  testId,
  id = "",
}: Props) => (
  <section
    className={`text-center ${
      isPrimary ? "bg-white" : "bg-gray-900"
    } ${classNames}`}
    data-cy={testId}
    id={id}
  >
    <h2
      className={
        sectionClassNames.title ||
        `uppercase font-primary text-8xl font-bold leading-3none md:leading-2tight md:text-3xxl md:ls-1.3${titleClassNames &&
          ` ${titleClassNames}`} ${
          isPrimary ? "text-black mb-25" : "text-white mb-30"
        }`
      }
    >
      {title}
    </h2>
    {subtitle && (
      <p
        className={
          sectionClassNames.subtitle ||
          `font-base-medium leading-3normal text-lg md:leading-5relaxed md:max-w-750${subtitleClassNames &&
            ` ${subtitleClassNames}`} ${
            isPrimary
              ? "text-gray-600 mr-30 ml-30 mb-20 md:mr-auto md:ml-auto"
              : "text-white"
          }`
        }
      >
        {subtitle}
      </p>
    )}
    {children}
  </section>
)

export default memo<Props>(DefaultSection)
