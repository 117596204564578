// @flow
import React from "react"
import type { LabelType } from "@lesmills/gatsby-theme-common"
import LazyLoad from "react-lazyload"

type Props = {|
  platforms: {
    stream_platform_name: Array<LabelType>,
    stream_platform_details: Array<LabelType>,
  },
  classNames: Object,
|}

const StreamPlatforms = ({ platforms, classNames }: Props) => {
  return (
    <>
      <ul className="flex flex-wrap sm:flex-no-wrap w-80/100 md:max-w-70/100 lg:max-w-780 justify-between mx-auto stream-platform xs_max:w-80/100">
        {platforms.map(
          (item, index) =>
            item.stream_platform_icon &&
            item.stream_platform_icon.url && (
              <li
                className="flex flex-col items-center mb-32 sm:mb-0"
                key={"platform-" + index}
              >
                <LazyLoad once>
                  <img
                    src={item.stream_platform_icon.url}
                    className="max-w-70 max-h-70"
                  />
                </LazyLoad>
                <p
                  className={
                    classNames.platformName ||
                    "font-primary uppercase mt-18 text-gray-800 sm:text-2lg sm:leading-24 text-3xs leading-16 max-w-70/100 md:max-w-49/100 lg:max-w-full flex justify-center"
                  }
                >
                  {item.stream_platform_name.text}
                </p>
              </li>
            )
        )}
      </ul>
      <ul
        className={`flex sm:w-full max-w-60/100 justify-between mt-20 mx-auto xs_sm:max-w-60/100 xs_sm:mx-auto ${classNames.deviceIcons ||
          "lg:max-w-715 sm:mt-50 md:mt-60 mb-70"}`}
      >
        {platforms.map(
          (item, index) =>
            item.stream_device_icon &&
            item.stream_device_icon.url && (
              <li key={"device-" + index}>
                <LazyLoad once>
                  <picture>
                    <source
                      srcSet={item.stream_device_icon.url}
                      media="(min-width: 640px)"
                    />
                    <source
                      srcSet={
                        item.stream_device_icon.thumbnails &&
                        item.stream_device_icon.thumbnails.mobile
                          ? item.stream_device_icon.thumbnails.mobile.url
                          : ""
                      }
                      media="(max-width: 375px)"
                    />
                    <img
                      src={item.stream_device_icon.url}
                      className="w-50 h-50 md:w-74 md:h-74 lg:w-auto lg:h-auto"
                    />
                  </picture>
                </LazyLoad>
              </li>
            )
        )}
      </ul>
    </>
  )
}

export default StreamPlatforms
