import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import TrainYourWay from "../TrainYourWay"

import type { LabelType, ImageType } from "@lesmills/gatsby-theme-common"

type Props = {|
  data: Object,
  title: Array<LabelType>,
  subtitle: Array<LabelType>,
  photo: ImageType,
  classNames?: Object,
|}

const Stream = ({ data, title, subtitle, photo, classNames = {} }: Props) => {
  const formatedData = {
    primary: {
      title,
      subtitle,
      photo,
    },
  }
  const [stream_platforms = {}] = data || []
  const { items = [] } = stream_platforms

  return (
    <TrainYourWay
      data={formatedData}
      wrapperClassNames={`max-w-1160 mx-auto px-20 ${classNames.wrapper ||
        "pt-24"}`}
      sectionClassNames={{
        title:
          "text-gray-800 sm:leading-58 font-primary sm:text-xxl leading-24 text-3xl mb-20 uppercase",
        subtitle:
          "font-base-light text-xl sm:text-4.5xl sm:leading-36 leading-24 mb-50 mx-10 lg:mx-0 text-gray-800",
      }}
      isStream
      platforms={items}
      title={title}
      subtitle={subtitle}
      photo={photo}
      classNames={classNames}
      subtitleClassName={classNames.subtitle}
    />
  )
}

export default memo(Stream, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
