import React from "react"

type Props = {|
  handleDismiss: () => void,
  video: string,
|}

const EmbedVideoModal = ({ handleDismiss, video }: Props) => {
  return (
    <div
      onClick={() => handleDismiss()}
      className="fixed w-100vw h-100vh inset-0 z-102 flex justify-center"
    >
      <div
        className="modal-close w-close-icon h-close-icon absolute cursor-pointer w-close-icon top-20 right-15 z-100"
        onClick={() => handleDismiss()}
      />
      <div className="w-full h-full bg-gray-900 flex justify-center opacity-75 absolute" />
      <div
        className="mkt-embed-video w-80vw h-80vh md:h-90vh mt-10vh md:mt-5vh z-100"
        dangerouslySetInnerHTML={{
          __html: video ? video.html : "",
        }}
      />
    </div>
  )
}

export default EmbedVideoModal
