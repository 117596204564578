import React, { memo } from "react"
import LazyLoad from "react-lazyload"
import isEqual from "lodash/isEqual"
import Slider from "react-slick"

import type { LabelType } from "@lesmills/gatsby-theme-common"

import DefaultSection from "../DefaultSection"
import Community from "./Community"
import { NextArrow, PrevArrow } from "../SliderItems/Arrow"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

type Props = {
  data: Object,
  title: Array<LabelType>,
  subtitle: Array<LabelType>,
  subtitleClassName?: string,
  className?: string,
}

const JoinCommunity = ({
  data,
  title = {},
  subtitle = {},
  className,
  subtitleClassName = "",
}: Props) => {
  const [community_photos = {}] = data || []
  const items = community_photos.items || []

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    className: "w-full overflow-hidden",
    nextArrow: <NextArrow isCommunicateSection={true} />,
    prevArrow: <PrevArrow isCommunicateSection={true} />,
    adaptiveHeight: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.6,
          centerMode: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: null,
          prevArrow: null,
          centerMode: true,
        },
      },
    ],
  }

  return (
    <DefaultSection
      title={title.text}
      subtitle={subtitle.text}
      isPrimary
      classNames={className || "max-w-1722 bg-white mx-auto md:pt-15 pt-60"}
      sectionClassNames={{
        title:
          "uppercase text-6xl leading-30 font-primary sm:leading-28 text-center px-20 text-gray-800",
        subtitle: `font-avenir-roman text-base text-gray-800 leading-22 mt-16 text-center px-20 xs_md:max-w-80/100 xs_md:mx-auto${subtitleClassName}`,
      }}
      id="community"
    >
      <div className="mt-32">
        <LazyLoad once>
          <Slider {...settings}>
            {items &&
              items.map((item, idx) => (
                <Community community={item} key={`community-photo${idx}`} />
              ))}
          </Slider>
        </LazyLoad>
      </div>
    </DefaultSection>
  )
}

export default memo(JoinCommunity, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
