import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"

import type { ImageType, LabelType } from "@lesmills/gatsby-theme-common"

import { htmlSerializerUpdateStyle } from "@lesmills/gatsby-theme-common"

type Props = {|
  community: {
    community_photo: ImageType,
    community_photo_owner_name: Array<LabelType>,
    community_photo_hashtag: Array<LabelType>,
  },
|}

const Community = ({ community }: Props) => {
  const {
    community_photo = {},
    community_photo_owner_name = {},
    community_photo_hashtag = {},
  } = community
  return (
    <div className="shadow no-underline mb-30 mx-6 w-244">
      <img
        src={community_photo.url}
        alt={community_photo.alt}
        className="w-full h-244"
      />
      <div className="text-center py-20 md-py-10 uppercase">
        <RichText
          render={community_photo_owner_name.raw}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "font-primary uppercase text-base leading-20 text-gray-800"
          )}
        />
        <RichText
          render={community_photo_hashtag.raw}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "font-base-medium uppercase text-xs leading-18 mt-10 text-gray-800"
          )}
        />
      </div>
    </div>
  )
}

export default memo(Community, (prevProps, nextProps) => {
  return isEqual(prevProps.community, nextProps.community)
})
