import React, { memo } from "react"
import { RichText } from "prismic-reactjs"

import {
  Accordion,
  htmlSerializerUpdateStyle,
  isBrowser,
  linkResolver,
  Button,
} from "@lesmills/gatsby-theme-common"

import type { QuestionType } from "@lesmills/gatsby-theme-common"

type Props = {|
  questions: Array<QuestionType>,
  readLessBtn: Object,
  handleToggleContent: () => void,
  classNames: Object,
|}

type CollapseBtnProps = {|
  handleCollapse: () => void,
|}

const QuestionList = ({
  handleToggleContent,
  questions,
  readLessBtn,
  classNames,
}: Props) => {
  const renderCollapseBtn = ({ handleCollapse }: CollapseBtnProps) => (
    <Button
      className="underline text-white font-base-light text-base mt-15"
      handleOnClick={handleCollapse}
    >
      {readLessBtn.text}
    </Button>
  )

  const list = questions.map(item => {
    return {
      title: item.faq_question.text,
      children: (
        <div className="text-white font-base-light text-base mt-15">
          {isBrowser && (
            <RichText
              render={item.faq_answer.raw}
              linkResolver={linkResolver}
              htmlSerializer={htmlSerializerUpdateStyle(
                "",
                "break-words lg:mb-40 mb-30 faq"
              )}
            />
          )}
        </div>
      ),
      collapseBtn: renderCollapseBtn,
    }
  })

  return (
    <Accordion
      list={list}
      handleToggleFromOutside={handleToggleContent}
      testId="question-accordion"
      classNames={{
        wrapper:
          "sm:mt-0 mt-10 flex md:justify-between justify-center flex-wrap 2xl:max-w-820 lg:max-w-780 mx-auto w-80/100 sm:mt-25",
        item: {
          wrapper: ` ${
            classNames ? "mt-74" : "mt-25"
          } md:w-46/100 w-full accordion-item-white relative`,
          title:
            "font-primary text-2lg leading-24 text-white uppercase mr-4 max-w-80/100",
          icon: " absolute top-5 right-0",
        },
      }}
      icon="black-back"
    />
  )
}

export default memo(QuestionList)
