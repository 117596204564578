import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import { RichText } from "prismic-reactjs"

import {
  Icon,
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  data: Object,
  setVideo: () => void,
|}

const Video = ({ data, setVideo }: Props) => {
  const {
    embedded_video,
    embedded_video_name = { raw: [] },
    embedded_video_description = { raw: [] },
    thumbnail_image = { raw: [] },
  } = data
  const { url = "", alt } = thumbnail_image.raw[0] || {}
  return (
    <div className="relative flex items-center justify-center cursor-pointer mx-12 workout-video">
      <img
        src={url}
        onClick={() => (embedded_video.html ? setVideo(embedded_video) : {})}
        width="auto"
        height="auto"
        alt={alt ? alt : "embedded_video"}
      />
      {embedded_video && embedded_video.html && (
        <Icon
          icon="play"
          classNames="play-video-icon md:h-36 md:w-40 h-32 w-36 max-w-49/100 absolute cursor-pointer bg-cover opacity-90 z-20"
          handleOnClick={() => setVideo(embedded_video)}
        />
      )}
      <div className="flex flex-col absolute mb-20 text-white bottom-0 h-54 z-20">
        <h4 className="uppercase font-primary text-xl md:text-2xl leading-24">
          {embedded_video_name.text}
        </h4>
        <RichText
          render={embedded_video_description.raw}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "font-avenir-roman md:text-base text-3xs leading-22 mt-6"
          )}
        />
      </div>
    </div>
  )
}
export default memo(Video, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
